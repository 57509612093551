import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SubmitSelectedProductsToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SUBMIT_SELECTED_PRODUCTS_TO_TASK_QUERY = gql`
  mutation SubmitSelectedProductsToTask(
    $uuid: ID!
    $selectedProductIds: [ID!]!
  ) {
    submitSelectedProductsToTask(
      input: { uuid: $uuid, selectedProductIds: $selectedProductIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
