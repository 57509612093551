import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { ProductsListItemTagsProduct } from './ProductsListItemTags.types';

import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

interface ProductsListItemTagsProps {
  product: ProductsListItemTagsProduct;
}

function ProductsListItemTags({ product }: ProductsListItemTagsProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (isEmpty(product.tags)) {
    return null;
  }

  const tagsName = product.tags.map((tag) => tag.localizedName).join(', ');

  return (
    <div
      className="text-gray-600 dark:text-gray-500 text-2xs truncate"
      ref={setReferenceTooltipElement}
    >
      <span>{tagsName}</span>

      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        tooltipText={tagsName}
        interactive
      />
    </div>
  );
}

export default ProductsListItemTags;
