import { gql } from 'graphql-request';

import {
  ProjectUUID,
  ProjectSelectedProductsUUID,
  ProjectSelectedProductsProductUUID,
  ProjectSelectedProductsProductName,
  ProjectNanoID
} from '../projectsTypes';

export interface SubmitSelectedProductsToProjectQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  selectedProducts: {
    uuid: ProjectSelectedProductsUUID;
    product: {
      uuid: ProjectSelectedProductsProductUUID;
      name: ProjectSelectedProductsProductName;
    };
  }[];
}

export const SUBMIT_SELECTED_PRODUCTS_TO_PROJECT_QUERY = gql`
  mutation SubmitSelectedProductsToProject(
    $uuid: ID!
    $selectedProductIds: [ID!]!
  ) {
    submitSelectedProductsToProject(
      input: { uuid: $uuid, selectedProductIds: $selectedProductIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        selectedProducts {
          uuid
          product {
            uuid
            name
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
