import { useCallback } from 'react';

import { ProductsIndexTableRowProps } from './ProductsIndexTableRow.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useProductSelectButton } from '../../buttons/ProductSelectButton/hooks/useProductSelectButton';

import { IndexTableRow } from '../../../../common/components/tables/IndexTableRow';
import { IndexTableCell } from '../../../../common/components/tables/IndexTableCell';

import { ProductSelectButton } from '../../buttons/ProductSelectButton';
import { ProductFavoriteButton } from '../../buttons/ProductFavoriteButton';

import { ProductsIndexTableRowProductPreview } from './components/ProductsIndexTableRowProductPreview';
import { ProductsIndexTableRowBrandNameFilterButton } from './components/ProductsIndexTableRowBrandNameFilterButton';
import { ProductsIndexTableRowProductRenderType } from './components/ProductsIndexTableRowProductRenderType';
import { IndexTableCellDropdown } from '../../../../common/components/tables/IndexTableCellDropdown';
import { ProductsIndexTableRowDropdownContent } from './components/ProductsIndexTableRowDropdownContent';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../helpers/Translate';

import { ProductsPermissions } from '../../../productsConstants';
import { TeamPath } from '../../../../teams/TeamPath';
import { ProductPath } from '../../../ProductPath';

import { words } from '../../../../../locales/keys';

function ProductsIndexTableRow({
  changeProductsFilters,
  companyNanoId,
  onLightboxOpen,
  onProductAiSearch,
  onProductEditButtonMouseEnter,
  onProductSimilarSearch,
  onProductsSelect,
  onSelectedProductsSidebarClose,
  onSelectedProductsSidebarOpen,
  product,
  productsCacheKeys,
  productsFilters,
  productsSet,
  productsSetCacheKeys,
  updateProductCache,
  updateProductsSetCache
}: ProductsIndexTableRowProps) {
  const { isSelected, handleToggleSelected } = useProductSelectButton({
    product,
    productsSet,
    onSelectedProductsSidebarOpen,
    onSelectedProductsSidebarClose,
    updateProductsSetCache,
    onSelect: onProductsSelect,
    productsSetCacheKeys
  });

  const handleShowButtonMouseOver = useCallback<() => void>(
    () => onProductEditButtonMouseEnter?.(product.nanoId),
    [onProductEditButtonMouseEnter, product.nanoId]
  );

  const handleOpenLightbox = useCallback(() => {
    if (product.image) {
      onLightboxOpen(product.image);
    }
  }, [product, onLightboxOpen]);

  const productPagePath = companyNanoId
    ? TeamPath.companyLibraryProduct(companyNanoId, product.nanoId)
    : ProductPath.show(product.nanoId);

  return (
    <IndexTableRow
      className={isSelected ? 'group selected' : 'group'}
      itemId={product.id}
      itemUuid={product.uuid}
      scope="products"
    >
      {/* selecte */}
      <IndexTableCell
        tdClassName="py-0.5 px-2 sticky w-px focus-within:z-5 text-center left-0 group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName="flex items-center"
      >
        <ProductSelectButton
          isSelected={isSelected}
          handleToggleSelected={handleToggleSelected}
          selectedClassName="py-0.5 pl-0.5 pr-1 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md border border-transparent"
          unselectedClassName="tpy-0.5 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
        />
      </IndexTableCell>

      {/* favorite */}
      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_FAVORITE_BUTTON}
      >
        <IndexTableCell
          tdClassName="px-2 py-1.5 w-px relative group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
          divClassName="flex items-center justify-center absolute inset-0"
        >
          <ProductFavoriteButton
            product={product}
            productsCacheKeys={productsCacheKeys}
            productsSetCacheKeys={productsSetCacheKeys}
            updateProductCache={updateProductCache}
            className={
              product.favorite
                ? 'focus:ring-offset-0 items-center rounded-full text-pink-600 hover:text-pink-500 flex p-0.5'
                : 'focus:ring-offset-0 items-center rounded-full text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
            }
            icon={
              product.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE
            }
          />
        </IndexTableCell>
      </CheckPermissions>

      {/* product preview */}
      <IndexTableCell
        tdClassName="py-1 px-2 group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName="w-12 max-w-full mx-auto relative rounded-md group"
      >
        <ProductsIndexTableRowProductPreview
          product={product}
          onLightboxOpen={handleOpenLightbox}
        />
      </IndexTableCell>

      {/* product name */}
      <IndexTableCell
        tdClassName="py-1 px-2 text-xs group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName="text-gray-900 dark:text-gray-200 font-normal line-clamp-1"
      >
        <NextPureLinkHelper
          className="hover:underline"
          href={productPagePath}
          onClick={onLightboxOpen ? handleOpenLightbox : undefined}
          onMouseEnter={handleShowButtonMouseOver}
          text={product.name}
        />
      </IndexTableCell>

      {/* product brand */}
      <IndexTableCell
        tdClassName="py-1 px-2 text-xs group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName="text-gray-900 dark:text-gray-200 font-normal max-w-60 sm:max-w-80 xl:max-w-96 2xl:max-w-112 truncate"
      >
        <ProductsIndexTableRowBrandNameFilterButton
          product={product}
          changeProductsFilters={changeProductsFilters}
          productsFilters={productsFilters}
        />
      </IndexTableCell>

      {/* product SKU */}
      <IndexTableCell
        tdClassName="py-1 px-2 text-xs group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName="text-gray-900 dark:text-gray-200 whitespace-nowrap font-normal"
      >
        <span>{product.sku}</span>
      </IndexTableCell>

      {/* product NDA */}
      <IndexTableCell
        tdClassName="py-0.5 px-2 text-xs group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
        divClassName={
          product.nda
            ? 'inline-block leading-4 bg-yellow-200 text-black px-1 py-0.5 text-3xs font-medium rounded uppercase mr-1'
            : 'hidden'
        }
      >
        {product.nda ? <Translate id={words.nda} /> : null}
      </IndexTableCell>

      {/* product render type */}
      <CheckPermissions action={ProductsPermissions.READ_PRODUCT_RENDER_TYPES}>
        <IndexTableCell
          tdClassName="py-0.5 px-2 text-xs group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
          divClassName="flex items-center gap-x-1"
        >
          <ProductsIndexTableRowProductRenderType product={product} />
        </IndexTableCell>
      </CheckPermissions>

      {/* dropdown */}
      <IndexTableCellDropdown
        icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
        tabIndex={0}
        tdClassName="sticky focus-within:z-5 bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90 text-center group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 right-0 px-3 py-0.5 text-xs group-[.selected]:bg-blue-500 group-[.selected]:bg-opacity-15"
      >
        <ProductsIndexTableRowDropdownContent
          onLightboxOpen={onLightboxOpen}
          onProductAiSearch={onProductAiSearch}
          onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
          onProductSimilarSearch={onProductSimilarSearch}
          product={product}
        />
      </IndexTableCellDropdown>
    </IndexTableRow>
  );
}

export default ProductsIndexTableRow;
