import {
  ProductPreviewModelFile,
  ProductPreviewModelName
} from '../../productsTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { ModelViewerModalButton } from '../../../modelViewers/components/modalButtons/ModelViewerModalButton';

import { SimpleModalButtonDefaultProps } from '../../../../helpers/buttons/SimpleModalButton';

import { ProductsPermissions } from '../../productsConstants';

type ProductModelViewerModalButtonProps = Pick<
  SimpleModalButtonDefaultProps,
  | 'className'
  | 'title'
  | 'i18nTitle'
  | 'i18nText'
  | 'icon'
  | 'iconClassName'
  | 'tooltipPlacement'
  | 'tooltipI18nText'
  | 'modalSize'
> & {
  previewModel?: {
    file: ProductPreviewModelFile;
    name: ProductPreviewModelName;
  };
};

function ProductModelViewerModalButton({
  className,
  title,
  i18nTitle,
  i18nText,
  icon,
  iconClassName,
  tooltipPlacement,
  tooltipI18nText,
  modalSize,
  previewModel
}: ProductModelViewerModalButtonProps) {
  const currentUser = useCurrentUser();

  return (
    <ModelViewerModalButton
      className={className}
      title={title}
      i18nTitle={i18nTitle}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      modalSize={modalSize}
      model={previewModel}
      cameraControls
      withDownloadButton={currentUser.hasPermissions(
        ProductsPermissions.READ_PRODUCT_PREVIEW_MODEL_DOWNLOAD_BUTTON
      )}
    />
  );
}

export default ProductModelViewerModalButton;
