import React from 'react';
import map from 'lodash/map';

import { ProductsIndexTableRowProductRenderTypeProduct } from './ProductsIndexTableRowProductRenderType.types';

import { Icon } from '../../../../../../../helpers/Icon';

import { ProductRenderTypeIcons } from '../../../../../productsConstants';

interface ProductsIndexTableRowProductRenderTypeProps {
  product: ProductsIndexTableRowProductRenderTypeProduct;
}

function ProductsIndexTableRowProductRenderType({
  product
}: ProductsIndexTableRowProductRenderTypeProps) {
  return map(product.renderTypes, (type) => (
    <Icon
      key={type.name}
      icon={ProductRenderTypeIcons[type.name]}
      className="rounded h-5 w-5"
    />
  ));
}

export default ProductsIndexTableRowProductRenderType;
