import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsIndexTableRowAiSearchButtonProduct } from './ProductsIndexTableRowAiSearchButton.types';
import { OnProductAiSearchAction } from '../../../../../productsTypes';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsIndexTableRowAiSearchButtonProps {
  product: ProductsIndexTableRowAiSearchButtonProduct;
  onProductAiSearch?: OnProductAiSearchAction;
}

function ProductsIndexTableRowAiSearchButton({
  product,
  onProductAiSearch
}: ProductsIndexTableRowAiSearchButtonProps) {
  const handleProductAiSearch = useCallback<() => void>(() => {
    onProductAiSearch?.(product.id);
  }, [product, onProductAiSearch]);

  return (
    <PureIconButtonHelper
      i18nText={aiSearchKeys.findAlternatives}
      icon={IconsEnum.SEARCH_AI_OUTLINE}
      iconClassName="h-5 w-5 mr-2"
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      onClick={handleProductAiSearch}
    />
  );
}

export default ProductsIndexTableRowAiSearchButton;
