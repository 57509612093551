import React, { useCallback } from 'react';

import { ProductNanoID } from '../../../../../productsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsIndexTableRowEditLinkProduct } from './ProductsIndexTableRowEditLink.type';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { ProductPath } from '../../../../../ProductPath';

import { words } from '../../../../../../../locales/keys';

interface ProductsIndexTableRowEditLinkProps {
  product: ProductsIndexTableRowEditLinkProduct;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
}

function ProductsIndexTableRowEditLink({
  product,
  onProductEditButtonMouseEnter
}: ProductsIndexTableRowEditLinkProps) {
  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onProductEditButtonMouseEnter?.(product.nanoId);
  }, [onProductEditButtonMouseEnter, product.nanoId]);

  return (
    <NextPureLinkHelper
      href={ProductPath.edit(product.nanoId)}
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      icon={IconsEnum.PENCIL}
      iconClassName="h-5 w-5 stroke-1.75 mr-2"
      i18nText={words.edit}
      onMouseEnter={handleEditButtonMouseEnter}
    />
  );
}

export default ProductsIndexTableRowEditLink;
