import React from 'react';

import { Translate } from '../../../../../../../helpers/Translate';

import { ProductsListItemNdaProduct } from './ProductsListItemNda.types';

interface ProductsListItemNdaProps {
  product: ProductsListItemNdaProduct;
}

function ProductsListItemNda({ product }: ProductsListItemNdaProps) {
  if (!product.nda) {
    return null;
  }

  return (
    <span
      id="attachment-2-nda"
      className="bg-yellow-200 px-1 py-0.5 text-xs rounded uppercase mr-1"
    >
      <Translate id="forms.fields.nda" />
    </span>
  );
}

export default ProductsListItemNda;
