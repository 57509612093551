import { useCallback } from 'react';

import { TaskUUID } from '../../../tasks/tasksTypes';
import { ProductID, ProductUUID } from '../../productsTypes';

import {
  FetchProductsSetByUserIdQueryResponse,
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY
} from '../../../productsSets/queries/fetchProductsSetByUserId.query';
import { TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY } from '../../../productsSets/queries/toggleProductInProductsSet.query';
import {
  SubmitSelectedProductsToTaskQueryResponse,
  SUBMIT_SELECTED_PRODUCTS_TO_TASK_QUERY
} from '../../../tasks/queries/submitSelectedProductsToTask.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useProductsSetByUserId } from '../../../productsSets/hooks/useProductsSetByUserId';
import { useToggleProductInProductsSet } from '../../../productsSets/hooks/useToggleProductInProductsSet';
import { useSubmitSelectedProductsToTask } from '../../../tasks/hooks/useSubmitSelectedProductsToTask';

import { ProductsSetCache } from '../../../productsSets/ProductsSetCache';
import { ProductsSetScope } from '../../../productsSets/ProductsSetScope';

interface SubmitProductToTaskOptionsProduct {
  uuid: ProductUUID;
  id: ProductID;
}

interface SubmitProductToTaskOptions {
  product: SubmitProductToTaskOptionsProduct;
}

function useSubmitProductToTask({ product }: SubmitProductToTaskOptions) {
  const currentUser = useCurrentUser();

  const {
    productsSet,
    productsSetError,
    productsSetLoading,
    updateProductsSetCache
  } = useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
    cacheKey: ProductsSetCache.submitToTaskCacheKey(),
    query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: ProductsSetScope.submitToTaskScope(currentUser.id)
  });

  const {
    toggleProductInProductsSetErrorMessage,
    toggleProductInProductsSet,
    toggleProductInProductsSetLoading
  } = useToggleProductInProductsSet({
    query: TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
    cacheKeys: [ProductsSetCache.submitToTaskCacheKey()],
    product,
    updateProductsSetCache
  });

  const {
    submitSelectedProductsToTaskErrorMessage,
    submitSelectedProductsToTaskLoading,
    submitSelectedProductsToTask,
    submitSelectedProductsToTaskReset
  } = useSubmitSelectedProductsToTask<SubmitSelectedProductsToTaskQueryResponse>(
    {
      query: SUBMIT_SELECTED_PRODUCTS_TO_TASK_QUERY,
      cacheKeys: [ProductsSetCache.submitToTaskCacheKey()]
    }
  );

  const submitProductToTask = useCallback<
    (taskUuid: TaskUUID) => Promise<unknown>
  >(
    async (taskUuid) => {
      const data = await toggleProductInProductsSet({
        productId: product.id,
        uuid: productsSet.uuid
      });

      return submitSelectedProductsToTask({
        uuid: taskUuid,
        selectedProductIds:
          data.toggleProductInProductsSet?.record?.selectedProducts?.map(
            (selectedProduct) => selectedProduct.id
          )
      });
    },
    [
      product.id,
      productsSet,
      submitSelectedProductsToTask,
      toggleProductInProductsSet
    ]
  );

  return {
    submitProductToTask,
    submitProductToTaskReset: submitSelectedProductsToTaskReset,
    submitProductToTaskError:
      productsSetError ||
      toggleProductInProductsSetErrorMessage ||
      submitSelectedProductsToTaskErrorMessage,
    submitProductToTaskIsLoading:
      productsSetLoading ||
      toggleProductInProductsSetLoading ||
      submitSelectedProductsToTaskLoading
  };
}

export default useSubmitProductToTask;
