import React from 'react';

import { Translate } from '../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProductsPermissions } from '../../../productsConstants';

import { words } from '../../../../../locales/keys';

function ProductsIndexTableHeader() {
  return (
    <thead>
      <tr className="bg-white dark:bg-gray-900">
        <th scope="col" colSpan={1} className="p-0 w-px" />
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCT_FAVORITE_BUTTON}
        >
          <th scope="col" colSpan={1} className="p-0 w-px" />
        </CheckPermissions>
        <th scope="col" colSpan={1} className="p-0 w-px" />
        <th scope="col" colSpan={1} className="px-2 py-2 z-10">
          <div className="flex items-center">
            <div className="text-xs font-semibold whitespace-nowrap">
              <Translate id={words.name} />
            </div>
          </div>
        </th>
        <th scope="col" colSpan={1} className="px-2 py-2 z-10 w-px">
          <div className="flex items-center">
            <div className="text-xs font-semibold whitespace-nowrap">
              <Translate id={words.brand} />
            </div>
          </div>
        </th>
        <th scope="col" colSpan={1} className="px-2 py-2 z-10 w-px">
          <div className="flex items-center">
            <div className="text-xs font-semibold whitespace-nowrap">
              <Translate id={words.sku} />
            </div>
          </div>
        </th>
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCT_RENDER_TYPES}
        >
          <th scope="col" colSpan={1} className="p-0 w-px" />
        </CheckPermissions>
        <th scope="col" colSpan={1} className="p-0 w-px" />
        <th scope="col" colSpan={1} className="p-0 w-px xl:pr-4" />
      </tr>
    </thead>
  );
}

export default ProductsIndexTableHeader;
