import React, { Fragment } from 'react';

import {
  FetchProductsCacheKeys,
  OnSelectedProductsSidebarCloseAction,
  OnSelectedProductsSidebarOpenAction,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import { ProductsListItemLightboxDownloadButton } from '../ProductsListItemLightboxDownloadButton';
import { ProductsListItemLightboxFavoriteButton } from '../ProductsListItemLightboxFavoriteButton';
import { ProductsListItemLightboxSelectButton } from '../ProductsListItemLightboxSelectButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  ProductsListItemLightboxBottomButtonsProduct,
  ProductsListItemLightboxBottomButtonsProductsSet
} from './ProductsListItemLightboxBottomButtons.types';

import { ProductsPermissions } from '../../../../../productsConstants';

interface ProductsListItemLightboxBottomButtonsDefaultProps {
  product: ProductsListItemLightboxBottomButtonsProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  updateProductCache?: UpdateProductCacheAction<ProductsListItemLightboxBottomButtonsProduct>;
}

interface ProductsListItemLightboxBottomButtonsWithSelectProps {
  productsSet: ProductsListItemLightboxBottomButtonsProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListItemLightboxBottomButtonsProductsSet>;
}

interface ProductsListItemLightboxBottomButtonsWithoutProps {
  productsSet?: never;
  productsSetCacheKeys?: never;
  onSelectedProductsSidebarOpen?: never;
  onSelectedProductsSidebarClose?: never;
  updateProductsSetCache?: never;
}

type ProductsListItemLightboxBottomButtonsProps =
  ProductsListItemLightboxBottomButtonsDefaultProps &
    (
      | ProductsListItemLightboxBottomButtonsWithSelectProps
      | ProductsListItemLightboxBottomButtonsWithoutProps
    );

function ProductsListItemLightboxBottomButtons({
  product,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  updateProductCache
}: ProductsListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      {onSelectedProductsSidebarOpen && (
        <ProductsListItemLightboxSelectButton
          product={product}
          productsSet={productsSet}
          productsSetCacheKeys={productsSetCacheKeys}
          onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
          onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
          updateProductsSetCache={updateProductsSetCache}
        />
      )}
      <ProductsListItemLightboxFavoriteButton
        product={product}
        productsCacheKeys={productsCacheKeys}
        productsSetCacheKeys={productsSetCacheKeys}
        updateProductCache={updateProductCache}
      />
      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON}
      >
        <ProductsListItemLightboxDownloadButton product={product} />
      </CheckPermissions>
    </Fragment>
  );
}

export default ProductsListItemLightboxBottomButtons;
