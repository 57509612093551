import React from 'react';

import { TextWithTooltip } from '../../../../../../../helpers/TextWithTooltip';
import { Translate } from '../../../../../../../helpers/Translate';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsListItemLightboxTitleProduct } from './ProductsListItemLightboxTitle.types';

interface ProductsListItemLightboxTitleProps {
  product: ProductsListItemLightboxTitleProduct;
}

function ProductsListItemLightboxTitle({
  product
}: ProductsListItemLightboxTitleProps) {
  return (
    <div className="flex-1 overflow-hidden p-1.5 text-left">
      <div className="text-sm">
        <div className="text-white truncate">
          {product.blocked ? (
            <span className="bg-red-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.blocked" />
            </span>
          ) : null}
          <TextWithTooltip
            text={product.name}
            className="font-medium"
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-300 truncate">
          {product.nda ? (
            <span className="bg-yellow-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.nda" />
            </span>
          ) : null}
          {product.brand ? (
            <TextWithTooltip
              text={product.brand.localizedName}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ProductsListItemLightboxTitle;
