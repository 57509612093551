import { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import some from 'lodash/some';
import compact from 'lodash/compact';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import {
  OnSelectedProductsSidebarOpenAction,
  OnSelectedProductsSidebarCloseAction
} from '../../../../../productsTypes';

import { TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY } from '../../../../../../productsSets/queries/toggleProductInProductsSet.query';

import { useToggleProductInProductsSet } from '../../../../../../productsSets/hooks/useToggleProductInProductsSet';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import {
  ProductSelectButtonProduct,
  ProductSelectButtonProductsSet,
  ProductSelectButtonOnSelect
} from '../../ProductSelectButton.types';
import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';

interface UseProductSelectButtonOptions {
  product: ProductSelectButtonProduct;
  productsSet: ProductSelectButtonProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen?: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose?: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductSelectButtonProductsSet>;
  onSelect?: ProductSelectButtonOnSelect;
}

function useProductSelectButton({
  product,
  productsSet,
  productsSetCacheKeys,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  onSelect
}: UseProductSelectButtonOptions) {
  const isSelected = useMemo<boolean>(() => {
    if (!productsSet || !productsSet.selectedProducts) {
      return false;
    }
    return some(
      productsSet.selectedProducts,
      (selectedProduct) => selectedProduct?.product.uuid === product.uuid
    );
  }, [product, productsSet]);

  const { toggleProductInProductsSetErrorMessage, toggleProductInProductsSet } =
    useToggleProductInProductsSet({
      query: TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
      cacheKeys: compact([
        ProductsSetCache.showCacheKey(),
        ...(productsSetCacheKeys || [])
      ]),
      product,
      updateProductsSetCache
    });

  const selectedProductsAmount = size(productsSet?.selectedProducts);

  const handleAfterToggle = useCallback<() => void>(() => {
    if (selectedProductsAmount === 0 && !isSelected) {
      onSelectedProductsSidebarOpen?.();
    }
    if (selectedProductsAmount === 1 && isSelected) {
      onSelectedProductsSidebarClose?.();
    }
  }, [
    isSelected,
    selectedProductsAmount,
    onSelectedProductsSidebarOpen,
    onSelectedProductsSidebarClose
  ]);

  const productsSetUuid = productsSet?.uuid;
  const productId = product?.id;

  const handleToggleSelected = useCallback<() => void>(() => {
    if (!productsSetUuid || !productId) {
      return;
    }

    toggleProductInProductsSet({
      uuid: productsSetUuid,
      productId
    }).then((res) =>
      onSelect?.(res?.toggleProductInProductsSet?.record?.selectedProducts)
    );

    handleAfterToggle();
  }, [
    productsSetUuid,
    productId,
    toggleProductInProductsSet,
    handleAfterToggle,
    onSelect
  ]);

  useShowToastOnErrorChange({ error: toggleProductInProductsSetErrorMessage });

  return {
    isSelected,
    handleToggleSelected
  };
}

export default useProductSelectButton;
