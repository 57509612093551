import {
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus,
  ProjectGqlError,
  FetchProjectCacheKey,
  ProjectUUID,
  ProjectSelectedProductsID,
  ProjectNanoID
} from '../../projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedProductsToProjectInput {
  uuid: ProjectUUID | ProjectNanoID;
  selectedProductIds: ProjectSelectedProductsID[];
}

interface SubmitSelectedProductsToProjectResponse<
  SubmitSelectedProductsToProjectRecordType
> {
  SubmitSelectedProductsToProject: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: SubmitSelectedProductsToProjectRecordType;
    errors: SubmitSelectedProductsToProjectErrors;
  };
}

interface SubmitSelectedProductsToProjectErrors {
  fullMessages: ProjectGqlError;
}

interface SubmitSelectedProductsToProjectOptions {
  query: UpdateProjectGqlQuery;
  cacheKeys: FetchProjectCacheKey[];
}

const action = 'submitSelectedProductsToProject';

function useSubmitSelectedProductsToProject<
  SubmitSelectedProductsToProjectRecordType
>({ query, cacheKeys }: SubmitSelectedProductsToProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedProductsToProjectInput,
    SubmitSelectedProductsToProjectResponse<SubmitSelectedProductsToProjectRecordType>,
    SubmitSelectedProductsToProjectErrors,
    SubmitSelectedProductsToProjectRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedProductsToProjectData: createQueryData,
    submitSelectedProductsToProjectError: createQueryError,
    submitSelectedProductsToProjectErrorMessage: createQueryErrorMessage,
    submitSelectedProductsToProjectLoading: createQueryLoading,
    submitSelectedProductsToProject: createQuery,
    submitSelectedProductsToProjectReset: createQueryReset
  };
}

export default useSubmitSelectedProductsToProject;
