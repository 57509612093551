import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { OnProductSimilarSearchAction } from '../../../../../productsTypes';

import { ProductsAiSearchProduct } from '../../../../containers/ProductsAiSearch';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsListItemSimilarSearchButtonProps {
  onProductSimilarSearch?: OnProductSimilarSearchAction;
  product: ProductsAiSearchProduct;
}

function ProductsListItemSimilarSearchButton({
  onProductSimilarSearch,
  product
}: ProductsListItemSimilarSearchButtonProps) {
  const handleProductSimilarSearch = useCallback<() => void>(() => {
    onProductSimilarSearch?.(product);
  }, [product, onProductSimilarSearch]);

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      icon={IconsEnum.PHOTO_SOLID}
      iconClassName="h-5 w-5"
      onClick={handleProductSimilarSearch}
      tooltipI18nText={aiSearchKeys.findSimilar}
      tooltipSingleton
    />
  );
}

export default ProductsListItemSimilarSearchButton;
