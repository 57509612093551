import { useCallback } from 'react';

import { ProductID, ProductUUID } from '../../productsTypes';
import { ProjectUUID } from '../../../projects/projectsTypes';
import { TaskUUID } from '../../../tasks/tasksTypes';

import { useSubmitProductToProject } from '../useSubmitProductToProject';
import { useSubmitProductToTask } from '../useSubmitProductToTask';

interface SubmitProductToProjectOrTaskOptionsProduct {
  uuid: ProductUUID;
  id: ProductID;
}

interface SubmitProductToProjectOrTaskOptions {
  product: SubmitProductToProjectOrTaskOptionsProduct;
}

interface HandleSubmitProductToProjectOrTaskProps {
  projectUuid: ProjectUUID;
  taskUuid: TaskUUID | null;
}

function useSubmitProductToProjectOrTask({
  product
}: SubmitProductToProjectOrTaskOptions) {
  const {
    submitProductToTask,
    submitProductToTaskError,
    submitProductToTaskIsLoading,
    submitProductToTaskReset
  } = useSubmitProductToTask({ product });

  const {
    submitProductToProject,
    submitProductToProjectError,
    submitProductToProjectIsLoading,
    submitProductToProjectReset
  } = useSubmitProductToProject({ product });

  const submitProductToProjectOrTask = useCallback<
    ({
      projectUuid,
      taskUuid
    }: HandleSubmitProductToProjectOrTaskProps) => Promise<unknown>
  >(
    async ({ projectUuid, taskUuid }) => {
      if (taskUuid) {
        return submitProductToTask(taskUuid);
      }

      return submitProductToProject(projectUuid);
    },
    [submitProductToTask, submitProductToProject]
  );

  const submitProductToProjectOrTaskReset = useCallback<() => void>(() => {
    submitProductToProjectReset();
    submitProductToTaskReset();
  }, [submitProductToProjectReset, submitProductToTaskReset]);

  return {
    submitProductToProjectOrTask,
    submitProductToProjectOrTaskReset,
    submitProductToProjectOrTaskError:
      submitProductToTaskError || submitProductToProjectError,
    submitProductToProjectOrTaskIsLoading:
      submitProductToTaskIsLoading || submitProductToProjectIsLoading
  };
}

export default useSubmitProductToProjectOrTask;
