import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { ProductUUID } from '../../../productsTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadProduct } from '../../../hooks/useDownloadProduct';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface DownloadProductButtonProps {
  productUuid: ProductUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadProductButton({
  productUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadProductButtonProps) {
  const {
    downloadProductLoading,
    downloadProductErrorMessage,
    downloadProduct
  } = useDownloadProduct();

  useShowToastOnErrorChange({ error: downloadProductErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadProduct({
      uuid: productUuid,
      deviceNanoId: downloadNanoId
    });
  }, [productUuid, downloadNanoId, downloadProduct]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      disabled={downloadProductLoading}
      onClick={handleClick}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton={tooltipSingleton}
      tooltipPlacement={tooltipPlacement}
    />
  );
}

export default DownloadProductButton;
