import {
  FetchProductsSetProductClientId,
  FetchProductsSetByUserIdScopeType
} from './productsSetsTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class ProductsSetScope {
  static userLibraryScope(
    productClientId: FetchProductsSetProductClientId
  ): FetchProductsSetByUserIdScopeType {
    return `user-${productClientId}-library`;
  }

  static companyLibraryScope(
    teamNanoId: TeamNanoID
  ): FetchProductsSetByUserIdScopeType {
    return `company-${teamNanoId}-library`;
  }

  static submitToTaskScope(
    productClientId: FetchProductsSetProductClientId
  ): FetchProductsSetByUserIdScopeType {
    return `${productClientId}-submit-to-task`;
  }

  static submitToProjectScope(
    productClientId: FetchProductsSetProductClientId
  ): FetchProductsSetByUserIdScopeType {
    return `${productClientId}-submit-to-project`;
  }
}
