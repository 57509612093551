import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../productsTypes';
import { ProductFavoriteButtonProduct } from './ProductFavoriteButton.types';
import { FetchProductsSetsCacheKeys } from '../../../../productsSets/productsSetsTypes';

import {
  TOGGLE_PRODUCT_FAVORITE_QUERY,
  ToggleProductFavoriteQueryResponse
} from '../../../queries/toggleProductFavorite.query';

import { useToggleProductFavorite } from '../../../hooks/useToggleProductFavorite';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { ProductsSetCache } from '../../../../productsSets/ProductsSetCache';
import { ProductCache } from '../../../ProductCache';

interface useProductFavoriteButtonProps {
  product: ProductFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductCache?: UpdateProductCacheAction<ProductFavoriteButtonProduct>;
}

function useProductFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductCache
}: useProductFavoriteButtonProps) {
  const {
    toggleProductFavorite,
    toggleProductFavoriteErrorMessage,
    toggleProductFavoriteLoading
  } = useToggleProductFavorite<ToggleProductFavoriteQueryResponse>({
    query: TOGGLE_PRODUCT_FAVORITE_QUERY,
    cacheKeys: compact([
      ProductCache.indexCacheKey(),
      ProductsSetCache.showCacheKey(),
      ...(productsCacheKeys || []),
      ...(productsSetCacheKeys || [])
    ]),
    updateProductCache
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleProductFavorite({ uuid: product.uuid }),
    [product, toggleProductFavorite]
  );

  useShowToastOnErrorChange({ error: toggleProductFavoriteErrorMessage });

  return {
    handleToggleFavorite,
    toggleProductFavoriteLoading
  };
}

export default useProductFavoriteButton;
