import React, { useState } from 'react';
import toString from 'lodash/toString';

import { ProductsListItemScoreProduct } from './ProductsListItemScore.types';

import { Translate } from '../../../../../../../helpers/Translate';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../../../locales/keys';

interface ProductsListItemScoreProps {
  product: ProductsListItemScoreProduct;
}

function ProductsListItemScore({ product }: ProductsListItemScoreProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!product.score) {
    return null;
  }

  return (
    <div
      className="text-gray-600 dark:text-gray-500 text-2xs truncate"
      ref={setReferenceTooltipElement}
    >
      <span>
        <Translate id={words.score} />
        {': '} {product.score}
      </span>

      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        tooltipText={toString(product.score)}
        interactive
      />
    </div>
  );
}

export default ProductsListItemScore;
