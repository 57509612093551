import React, { useCallback } from 'react';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsListItemAiSearchButtonProduct } from './ProductsListItemAiSearchButton.types';
import { OnProductAiSearchAction } from '../../../../../productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsListItemAiSearchButtonProps {
  onProductAiSearch?: OnProductAiSearchAction;
  product: ProductsListItemAiSearchButtonProduct;
}

function ProductsListItemAiSearchButton({
  onProductAiSearch,
  product
}: ProductsListItemAiSearchButtonProps) {
  const handleProductAiSearch = useCallback<() => void>(() => {
    onProductAiSearch?.(product.id);
  }, [product, onProductAiSearch]);

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      icon={IconsEnum.SEARCH_AI_OUTLINE}
      iconClassName="h-5 w-5"
      onClick={handleProductAiSearch}
      tooltipI18nText={aiSearchKeys.findAlternatives}
      tooltipSingleton
    />
  );
}

export default ProductsListItemAiSearchButton;
