import { useCallback } from 'react';

import { ProductsListItemUpdateImageVersionsProduct } from '../../ProductsListItemUpdateImageVersions.types';

import { RECREATE_IMAGE_VERSIONS_QUERY } from '../../../../../../../../images/queries/recreateImageVersions.query';

import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useRecreateImageVersions } from '../../../../../../../../images/hooks/useRecreateImageVersions';

import { ImageItemImageVersions } from '../../../../../../../../../helpers/ImageHelper';

interface ProductsListItemUpdateImageVersionsOptions {
  product: ProductsListItemUpdateImageVersionsProduct;
}

function useProductsListItemUpdateImageVersions({
  product
}: ProductsListItemUpdateImageVersionsOptions) {
  const {
    recreateImageVersions,
    recreateImageVersionsLoading,
    recreateImageVersionsErrorMessage
  } = useRecreateImageVersions({
    query: RECREATE_IMAGE_VERSIONS_QUERY
  });

  useShowToastOnErrorChange({ error: recreateImageVersionsErrorMessage });

  const productImageUuid = product?.image?.uuid;
  const handleUpdateImageVersions = useCallback(() => {
    if (productImageUuid) {
      return recreateImageVersions({
        uuid: productImageUuid,
        async: true,
        versions: [
          ImageItemImageVersions.MiniThumb48x48,
          ImageItemImageVersions.MiniThumb96x96,
          ImageItemImageVersions.MiniThumb144x144,
          ImageItemImageVersions.MiniThumb320x320,
          ImageItemImageVersions.MiniThumb640x640,
          ImageItemImageVersions.MiniThumb960x960,
          ImageItemImageVersions.BigThumb538x435
        ]
      });
    }
  }, [productImageUuid, recreateImageVersions]);

  return {
    updateImageVersions: handleUpdateImageVersions,
    updateImageVersionsLoading: recreateImageVersionsLoading
  };
}

export default useProductsListItemUpdateImageVersions;
