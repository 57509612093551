import { FetchProductsSetByUserIdResponse } from '../../../../types';

interface GetUpdatedProductsSetQueryDataOptions<ProductsSetByUserIdItemType> {
  updateFunction: (
    item: ProductsSetByUserIdItemType
  ) => Partial<ProductsSetByUserIdItemType>;
  previousQueryData: FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>;
  itemKey: string;
}

function getUpdatedProductsSetQueryData<ProductsSetByUserIdItemType>({
  updateFunction,
  previousQueryData,
  itemKey
}: GetUpdatedProductsSetQueryDataOptions<ProductsSetByUserIdItemType>): FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType> | null {
  const previousItem = previousQueryData?.[itemKey];

  if (!previousItem) {
    return null;
  }

  const updateData = updateFunction ? updateFunction(previousItem) : {};

  const updatedItem = {
    ...previousItem,
    optimisticUpdate: true,
    ...updateData
  };

  return {
    ...previousQueryData,
    [itemKey]: updatedItem
  };
}

export default getUpdatedProductsSetQueryData;
