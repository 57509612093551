import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadProduct } from '../../../../../hooks/useDownloadProduct';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../../../locales/keys';

import { ProductsListItemLightboxDownloadButtonProduct } from './ProductsListItemLightboxDownloadButton.types';

interface ProductsListItemLightboxFavoriteButtonProps {
  product: ProductsListItemLightboxDownloadButtonProduct;
}

function ProductsListItemLightboxFavoriteButton({
  product
}: ProductsListItemLightboxFavoriteButtonProps) {
  const {
    downloadProductLoading,
    downloadProductErrorMessage,
    downloadProduct
  } = useDownloadProduct();

  useShowToastOnErrorChange({ error: downloadProductErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();
  const productUuid = product.uuid;

  const handleClick = useCallback(() => {
    downloadProduct({
      uuid: productUuid,
      deviceNanoId: downloadNanoId
    });
  }, [productUuid, downloadNanoId, downloadProduct]);

  return (
    <PureTooltipIconButtonHelper
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      icon={IconsEnum.DOWNLOAD_OUTLINE}
      iconClassName="h-6 w-6"
      tooltipSingleton
      tooltipI18nText={words.download}
      disabled={downloadProductLoading}
      onClick={handleClick}
    />
  );
}

export default ProductsListItemLightboxFavoriteButton;
