import React from 'react';

import { ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { words } from '../../../../../locales/keys';

interface ProductSelectButtonProps {
  isSelected: boolean;
  handleToggleSelected: () => void;
  selectedClassName: ClassName;
  unselectedClassName: ClassName;
  iconClassName?: ClassName;
  selectedIcon?: IconsEnum;
  unselectedIcon?: IconsEnum;
}

function ProductSelectButton({
  isSelected,
  handleToggleSelected,
  selectedClassName,
  unselectedClassName,
  iconClassName,
  selectedIcon,
  unselectedIcon
}: ProductSelectButtonProps) {
  return (
    <PureTooltipIconButtonHelper
      className={isSelected ? selectedClassName : unselectedClassName}
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? selectedIcon || IconsEnum.CHECK : unselectedIcon}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={words.selectToAddToTheProject}
      i18nTextClassName="inline"
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default ProductSelectButton;
