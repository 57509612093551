import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';

import {
  FetchProductsSetByUserIdResponse,
  UpdateProductsSetByUserIdCacheAction
} from '../../types';

import { getUpdatedProductsSetQueryData } from './utils/getUpdatedProductsSetQueryData';

interface UpdateProductsSetCacheOptions {
  fullCacheKey: QueryKey;
  itemKey: string;
}

function useUpdateProductsSetCache<ProductsSetByUserIdItemType>({
  fullCacheKey,
  itemKey
}: UpdateProductsSetCacheOptions) {
  const queryClient = useQueryClient();

  const useUpdateProductsSetCache = useCallback<
    UpdateProductsSetByUserIdCacheAction<ProductsSetByUserIdItemType>
  >(
    ({ updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<
          FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
        >(fullCacheKey);

      if (!previousQueryData || !previousQueryData[itemKey]) {
        return null;
      }

      const updatedQueryData =
        getUpdatedProductsSetQueryData<ProductsSetByUserIdItemType>({
          updateFunction,
          previousQueryData,
          itemKey
        });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<
        FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
      >(fullCacheKey, updatedQueryData);

      return () => {
        queryClient.setQueryData<
          FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
        >(fullCacheKey, previousQueryData);
      };
    },
    [fullCacheKey, queryClient, itemKey]
  );

  return useUpdateProductsSetCache;
}

export default useUpdateProductsSetCache;
