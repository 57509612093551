import { gql } from 'graphql-request';

import {
  ProductsSetID,
  ProductsSetSelectedProductsID,
  ProductsSetSelectedProductsProductID,
  ProductsSetSelectedProductsProductUUID,
  ProductsSetSelectedProductsUUID,
  ProductsSetUUID
} from '../productsSetsTypes';

export interface ToggleProductInProductsSetQueryResponse {
  id: ProductsSetID;
  uuid: ProductsSetUUID;
  selectedProducts: {
    id: ProductsSetSelectedProductsID;
    uuid: ProductsSetSelectedProductsUUID;
    product: {
      id: ProductsSetSelectedProductsProductID;
      uuid: ProductsSetSelectedProductsProductUUID;
    };
  }[];
}

export const TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY = gql`
  mutation ToggleProductInProductsSet(
    $uuid: ID!
    $productId: ID!
    $clientMutationId: String
  ) {
    toggleProductInProductsSet(
      input: {
        uuid: $uuid
        productId: $productId
        clientMutationId: $clientMutationId
      }
    ) {
      status
      record {
        id
        uuid
        selectedProducts {
          id
          uuid
          product {
            id
            uuid
            name
            brand {
              uuid
              name
            }
            image {
              uuid
              file
            }
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
