import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import find from 'lodash/find';
import without from 'lodash/without';
import isArray from 'lodash/isArray';

import { UUID, ID } from '../../../../types';

import {
  ToggleProductInProductsSetGqlQuery,
  ToggleProductInProductsSetGqlStatus,
  ToggleProductInProductsSetCacheKeys,
  ToggleProductInProductsSetGqlError,
  ProductsSetSelectedProductsUUID,
  UpdateProductsSetCacheAction
} from '../../productsSetsTypes';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

import { TempSelectedProduct } from './utils/TempSelectedProduct';

import { ToggleProductInProductsSetProduct } from './useToggleProductInProductsSet.types';

interface ToggleProductInProductsSetInput {
  clientMutationId?: string;
  uuid: UUID;
  productId: ID;
}

interface ToggleProductInProductsSetError {
  fullMessages: ToggleProductInProductsSetGqlError;
}

interface ToggleProductInProductsSetResponse<
  ToggleProductInProductsSetItemType
> {
  toggleProductInProductsSet: {
    status: ToggleProductInProductsSetGqlStatus;
    record: ToggleProductInProductsSetItemType;
    errors: ToggleProductInProductsSetError;
  };
}

interface ToggleProductInProductsSetContext {
  rollback?: () => void;
}

interface ToggleProductInProductsSetOptions<
  ToggleProductInProductsSetItemType extends ToggleProductInProductsSetItem
> {
  query: ToggleProductInProductsSetGqlQuery;
  cacheKeys: ToggleProductInProductsSetCacheKeys;
  product?: ToggleProductInProductsSetProduct;
  updateProductsSetCache?: UpdateProductsSetCacheAction<ToggleProductInProductsSetItemType>;
}

const action = 'toggleProductInProductsSet';

interface ToggleProductInProductsSetItem {
  selectedProducts: {
    uuid: ProductsSetSelectedProductsUUID;
  }[];
}

function useToggleProductInProductsSet<
  ToggleProductInProductsSetItemType extends ToggleProductInProductsSetItem
>({
  query,
  cacheKeys,
  product,
  updateProductsSetCache
}: ToggleProductInProductsSetOptions<ToggleProductInProductsSetItemType>) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return (
      product &&
      updateProductsSetCache?.({
        updateFunction: (prevProductsSet) => {
          const prevSelectedProducts = prevProductsSet.selectedProducts;

          const selectedProduct = find(prevSelectedProducts, [
            'product.uuid',
            product.uuid
          ]);

          const newSelectedProducts = selectedProduct
            ? without(prevSelectedProducts, selectedProduct)
            : [...prevSelectedProducts, TempSelectedProduct.create(product)];

          return {
            ...prevProductsSet,
            selectedProducts: newSelectedProducts
          };
        }
      })
    );
  }, [product, updateProductsSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    ToggleProductInProductsSetResponse<ToggleProductInProductsSetItemType>,
    ToggleProductInProductsSetError | ClientError | Error,
    ToggleProductInProductsSetInput,
    ToggleProductInProductsSetContext
  >(
    (queryInput) =>
      updateItem<
        ToggleProductInProductsSetInput,
        ToggleProductInProductsSetResponse<ToggleProductInProductsSetItemType>
      >({
        query,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    toggleProductInProductsSetError: error,
    toggleProductInProductsSetLoading: isLoading,
    toggleProductInProductsSetErrorMessage: parseRequestError(error),
    toggleProductInProductsSet: mutateAsync,
    toggleProductInProductsSetReset: reset
  };
}

export default useToggleProductInProductsSet;
