import {
  ProductsSetUUID,
  FetchProductsSetProductClientId
} from './productsSetsTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class ProductsSetCache {
  static indexCacheKey() {
    return 'productsSets';
  }

  static showCacheKey() {
    return 'productsSet';
  }

  static submitToTaskCacheKey() {
    return 'productSet-submit-to-task';
  }

  static submitToProjectCacheKey() {
    return 'productSet-submit-to-project';
  }

  static showUserLibraryCacheKey(
    productClientId: FetchProductsSetProductClientId
  ) {
    return `productsSetUserLibrary-${productClientId}`;
  }

  static showCompanyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `productsSetCompanyLibrary-${companyNanoId}`;
  }

  static showQueryKey(productsSetUuid: ProductsSetUUID) {
    return ['productsSet', productsSetUuid];
  }
}
