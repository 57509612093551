import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../productsTypes';

import { FetchProductsSetsCacheKeys } from '../../../../productsSets/productsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useProductFavoriteButton from './useProductFavoriteButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { ProductFavoriteButtonProduct } from './ProductFavoriteButton.types';

import { words } from '../../../../../locales/keys';

interface ProductFavoriteButtonProps {
  product: ProductFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductCache?: UpdateProductCacheAction<ProductFavoriteButtonProduct>;
  icon?: IconsEnum;
  i18nText?: I18nText;
  className?: ClassName;
  iconClassName?: ClassName;
}

function ProductFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductCache,
  className,
  icon,
  i18nText,
  iconClassName
}: ProductFavoriteButtonProps) {
  const { handleToggleFavorite, toggleProductFavoriteLoading } =
    useProductFavoriteButton({
      product,
      productsCacheKeys,
      productsSetCacheKeys,
      updateProductCache
    });

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      tooltipI18nText={
        product.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      i18nText={i18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleFavorite}
      iconClassName={iconClassName}
      disabled={toggleProductFavoriteLoading}
    />
  );
}

export default ProductFavoriteButton;
