import {
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  TaskGqlError,
  FetchTaskCacheKey,
  TaskUUID,
  TaskSelectedProductsID,
  TaskNanoID
} from '../../tasksTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedProductsToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  selectedProductIds: TaskSelectedProductsID[];
}

interface SubmitSelectedProductsToTaskResponse<
  SubmitSelectedProductsToTaskRecordType
> {
  SubmitSelectedProductsToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: SubmitSelectedProductsToTaskRecordType;
    errors: SubmitSelectedProductsToTaskErrors;
  };
}

interface SubmitSelectedProductsToTaskErrors {
  fullMessages: TaskGqlError;
}

interface SubmitSelectedProductsToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys: FetchTaskCacheKey[];
}

const action = 'submitSelectedProductsToTask';

function useSubmitSelectedProductsToTask<
  SubmitSelectedProductsToTaskRecordType
>({ query, cacheKeys }: SubmitSelectedProductsToTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedProductsToTaskInput,
    SubmitSelectedProductsToTaskResponse<SubmitSelectedProductsToTaskRecordType>,
    SubmitSelectedProductsToTaskErrors,
    SubmitSelectedProductsToTaskRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedProductsToTaskData: createQueryData,
    submitSelectedProductsToTaskError: createQueryError,
    submitSelectedProductsToTaskErrorMessage: createQueryErrorMessage,
    submitSelectedProductsToTaskLoading: createQueryLoading,
    submitSelectedProductsToTask: createQuery,
    submitSelectedProductsToTaskReset: createQueryReset
  };
}

export default useSubmitSelectedProductsToTask;
