import { gql } from 'graphql-request';

import { ProductFavorite } from '../productsTypes';

export interface ToggleProductFavoriteQueryResponse {
  favorite: ProductFavorite;
}

export const TOGGLE_PRODUCT_FAVORITE_QUERY = gql`
  mutation ToggleProductFavorite($uuid: ID!) {
    toggleProductFavorite(input: { uuid: $uuid }) {
      clientMutationId
      status
      recordUuid
      record {
        favorite
      }
      errors {
        fullMessages
      }
    }
  }
`;
