import { useCallback } from 'react';

import { ProductID, ProductUUID } from '../../productsTypes';
import { ProjectUUID } from '../../../projects/projectsTypes';

import {
  FetchProductsSetByUserIdQueryResponse,
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY
} from '../../../productsSets/queries/fetchProductsSetByUserId.query';
import { TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY } from '../../../productsSets/queries/toggleProductInProductsSet.query';
import {
  SubmitSelectedProductsToProjectQueryResponse,
  SUBMIT_SELECTED_PRODUCTS_TO_PROJECT_QUERY
} from '../../../projects/queries/submitSelectedProductsToProject.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useProductsSetByUserId } from '../../../productsSets/hooks/useProductsSetByUserId';
import { useToggleProductInProductsSet } from '../../../productsSets/hooks/useToggleProductInProductsSet';
import { useSubmitSelectedProductsToProject } from '../../../projects/hooks/useSubmitSelectedProductsToProject';

import { ProductsSetCache } from '../../../productsSets/ProductsSetCache';
import { ProductsSetScope } from '../../../productsSets/ProductsSetScope';

interface SubmitProductToProjectOptionsProduct {
  uuid: ProductUUID;
  id: ProductID;
}

interface SubmitProductToProjectOptions {
  product: SubmitProductToProjectOptionsProduct;
}

function useSubmitProductToProject({ product }: SubmitProductToProjectOptions) {
  const currentUser = useCurrentUser();

  const {
    productsSet,
    productsSetError,
    productsSetLoading,
    updateProductsSetCache
  } = useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
    cacheKey: ProductsSetCache.submitToProjectCacheKey(),
    query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: ProductsSetScope.submitToProjectScope(currentUser.id)
  });

  const {
    toggleProductInProductsSetErrorMessage,
    toggleProductInProductsSet,
    toggleProductInProductsSetLoading
  } = useToggleProductInProductsSet({
    query: TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
    cacheKeys: [ProductsSetCache.submitToProjectCacheKey()],
    product,
    updateProductsSetCache
  });

  const {
    submitSelectedProductsToProjectErrorMessage,
    submitSelectedProductsToProjectLoading,
    submitSelectedProductsToProject,
    submitSelectedProductsToProjectReset
  } = useSubmitSelectedProductsToProject<SubmitSelectedProductsToProjectQueryResponse>(
    {
      query: SUBMIT_SELECTED_PRODUCTS_TO_PROJECT_QUERY,
      cacheKeys: [ProductsSetCache.submitToProjectCacheKey()]
    }
  );

  const submitProductToProject = useCallback<
    (projectUuid: ProjectUUID) => Promise<unknown>
  >(
    async (projectUuid) => {
      const data = await toggleProductInProductsSet({
        productId: product.id,
        uuid: productsSet.uuid
      });

      return submitSelectedProductsToProject({
        uuid: projectUuid,
        selectedProductIds:
          data.toggleProductInProductsSet?.record?.selectedProducts?.map(
            (selectedProduct) => selectedProduct.id
          )
      });
    },
    [
      product.id,
      productsSet,
      submitSelectedProductsToProject,
      toggleProductInProductsSet
    ]
  );

  return {
    submitProductToProject,
    submitProductToProjectReset: submitSelectedProductsToProjectReset,
    submitProductToProjectError:
      productsSetError ||
      toggleProductInProductsSetErrorMessage ||
      submitSelectedProductsToProjectErrorMessage,
    submitProductToProjectIsLoading:
      productsSetLoading ||
      toggleProductInProductsSetLoading ||
      submitSelectedProductsToProjectLoading
  };
}

export default useSubmitProductToProject;
